<template>
  <section class="patient-history">

    <pacient-header />

    <BaseModal>
      <component @click.stop
                 :is="componentName" />
    </BaseModal>

    <ul class="lista-history"
        data-test="lista-tratamente">
      <li class="relative pointer"
          @click="edit(item)"
          v-for="item in tratamente"
          :key="item.id">
          <span   v-show="item.pin"
                  class="pin-img">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.6358 1.37016e-06C14.7487 -0.000200242 14.8605 0.0218496 14.9648 0.0648905C15.0692 0.107931 15.1641 0.171119 15.244 0.250841L23.7485 8.75534C23.9095 8.91644 24 9.1349 24 9.36269C24 9.59047 23.9095 9.80893 23.7485 9.97003C22.9238 10.7947 21.9067 10.9803 21.1662 10.9803C20.8621 10.9803 20.5907 10.9493 20.3759 10.9133L14.9914 16.2977C15.1332 16.8688 15.2251 17.4511 15.2663 18.0381C15.3453 19.2442 15.2113 20.9365 14.0293 22.1186C13.8682 22.2796 13.6497 22.3701 13.422 22.3701C13.1942 22.3701 12.9757 22.2796 12.8146 22.1186L7.95416 17.2598L2.48723 22.7268C2.1522 23.0618 0.392888 24.2765 0.057862 23.9415C-0.277164 23.6064 0.937519 21.8454 1.27255 21.5121L6.73948 16.0452L1.88075 11.1847C1.7197 11.0236 1.62923 10.8052 1.62923 10.5774C1.62923 10.3496 1.7197 10.1311 1.88075 9.97003C3.06279 8.78799 4.7551 8.65226 5.96119 8.73301C6.54818 8.77414 7.13051 8.86612 7.7016 9.0079L13.0861 3.62515C13.0411 3.36352 13.0181 3.09858 13.0173 2.83312C13.0173 2.09434 13.2029 1.07724 14.0293 0.250841C14.1903 0.0902539 14.4084 4.96808e-05 14.6358 1.37016e-06Z" fill="#F0C700"/>
        </svg>
          </span>
        <i class="absolute top0 flex-center"
           v-html="svg[item.type]"></i>

        <div class="flex flex-between h100">
          <div>
            <h4 v-if="item.type === 'nota'"
                class="nota-title">
              {{ item.detalii.split(' ').slice(0, 24).join(' ') || 'Notă' }}
            </h4>
            <h4 v-else>
              {{ item.denumire || item.type | shorten }}
              <span>{{ item.dinte ? '| ' + item.dinte : ''  }}</span>
            </h4>
            <span>{{ moment(item.createdAt).format('DD MMM YYYY') }}</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;{{ item.pret }} lei</span>
            <span v-if="item.medic">&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;{{ item.medic }}</span>
          </div>

          <!-- Check if the history obj has images or docs -->
          <figure v-if="item.type !== 'document' && isImg(item)"
                  class="flex-center">
            <img v-for="(pic, index) in pics(item.pics)"
                 :key="pic.id"
                 @click.stop="showPhotoSwipe(item, index)"
                 class="h80"
                 :src="'/history-imgs/' + pacient._id + '/thumb-' + (pic ? pic.name : '') + '.png'"
                 alt="">
          </figure>

          <a v-else-if="item.pics && item.pics.length > 0"
             @click.stop
             :href="'/history-imgs/' + pacient._id + '/' + (item.pics && item.pics[0] ? item.pics[0].name : '')"
             target="_blank">
            <img class="opa4"
                 src="../assets/note.svg" alt="">
          </a>
        </div>
      </li>
    </ul>

    <v-photoswipe v-if="isOpen"
                  :isOpen="isOpen"
                  :items="items"
                  :options="options"
                  @close="hidePhotoSwipe"></v-photoswipe>

    <footer class="w100">
      <ul class="flex"
          data-test="tratamente-actions-wrapper">
        <li class="text-center mright20px"
            :cypress="key"
            v-for="(item,key) in meniu"
            :key="key">
          <span class="block text-center green mbott5px" v-html="item.label"></span>
          <i class="pointer flex-center mauto green-btn-hover"
             @click="openModal(item, key)"
             v-html="svg[key]"></i>
        </li>
      </ul>
    </footer>

  </section>
</template>

<script>

  import { PhotoSwipe, PhotoSwipeGallery } from 'v-photoswipe'
  import moment from 'moment'
  import 'moment/locale/ro'
  moment.locale('ro')
  import { mapState } from 'vuex'
  export default {
    name: 'patient-history',
    mounted () {
      this.refresh = setInterval(this.refreshData, 2000)
    },
    data () {
      return {
        meniu: {
          tratament: {
            modal: 'addTratamentModal',
            label: 'adaugă <br> tratament'
          },
          radiografie: {
            modal: 'addRadiografieModal',
            label: 'adaugă <br> radiografie'
          },
          document: {
            modal: 'addDocumentModal',
            label: 'adaugă <br> document'
          },
          nota: {
            modal: 'addNotaModal',
            label: 'adaugă <br> notă'
          },
          importaFisa: {
            modal: 'addImportaFisaModal',
            label: 'importă <br> fișă PDF'
          }
        },
        svg: {
          tratament: `<svg width="27px" height="44px" viewBox="0 0 27 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M16.1052113,24.1290323 L14.0524242,24.1290323 L14.0524242,11.3548387 C14.0524242,11.1389554 13.9578704,10.9355469 13.7958258,10.8007497 L10.9087599,8.40558844 L13.0397293,5.64346023 C13.14865,5.65697452 13.2552319,5.67741935 13.3681619,5.67741935 C14.8776821,5.67741935 16.1052113,4.40429692 16.1052113,2.83870968 C16.1052113,1.27312244 14.8776821,0 13.3681619,0 C11.8586416,0 10.6311124,1.27312244 10.6311124,2.83870968 C10.6311124,3.75768649 11.0607811,4.5675088 11.7163097,5.08694556 L9.41327241,8.07327365 C9.17705486,8.37925275 9.22483297,8.8262664 9.51985422,9.07021802 L12.6838995,11.69547 L12.6838995,24.1290323 L10.6311124,24.1290323 C10.2535653,24.1290323 9.94685002,24.4461001 9.94685002,24.8387097 L9.94685002,40.4516129 C9.94685002,42.4080772 11.4817627,44 13.3681619,44 C15.254561,44 16.7894737,42.4080772 16.7894737,40.4516129 L16.7894737,24.8387097 C16.7894737,24.4461001 16.4827584,24.1290323 16.1052113,24.1290323 Z M13.3681619,1.41935484 C14.122922,1.41935484 14.7366866,2.05591606 14.7366866,2.83870968 C14.7366866,3.6215033 14.122922,4.25806452 13.3681619,4.25806452 C12.6134017,4.25806452 11.9996371,3.6215033 11.9996371,2.83870968 C11.9996371,2.05591606 12.6134017,1.41935484 13.3681619,1.41935484 Z M15.420949,25.5483871 L15.420949,26.9677419 L11.3153748,26.9677419 L11.3153748,25.5483871 L15.420949,25.5483871 Z M13.3681619,42.5806452 C12.2365228,42.5806452 11.3153748,41.6252835 11.3153748,40.4516129 L11.3153748,28.3870968 L15.420949,28.3870968 L15.420949,40.4516129 C15.420949,41.6252835 14.4998009,42.5806452 13.3681619,42.5806452 Z" id="Shape"></path>
            <path d="M6.25263158,24.1290323 L4.16842105,24.1290323 L4.16842105,3.5483871 C4.16842105,3.15681708 3.85701065,2.83870968 3.47368421,2.83870968 L1.38947368,2.83870968 L1.38947368,1.41935484 L5.55789474,1.41935484 L5.55789474,0 L0.694736842,0 C0.311410405,0 0,0.318107403 0,0.709677419 L0,3.5483871 C0,3.93995711 0.311410405,4.25806452 0.694736842,4.25806452 L2.77894737,4.25806452 L2.77894737,24.1290323 L0.694736842,24.1290323 C0.311410405,24.1290323 0,24.4461001 0,24.8387097 L0,40.4516129 C0,42.4080772 1.55840867,44 3.47368421,44 C5.38895975,44 6.94736842,42.4080772 6.94736842,40.4516129 L6.94736842,24.8387097 C6.94736842,24.4461001 6.63595802,24.1290323 6.25263158,24.1290323 Z M5.55789474,25.5483871 L5.55789474,26.9677419 L1.38947368,26.9677419 L1.38947368,25.5483871 L5.55789474,25.5483871 Z M3.47368421,42.5806452 C2.32472243,42.5806452 1.38947368,41.6252835 1.38947368,40.4516129 L1.38947368,28.3870968 L5.55789474,28.3870968 L5.55789474,40.4516129 C5.55789474,41.6252835 4.62264599,42.5806452 3.47368421,42.5806452 Z" id="Shape"></path>
            <path d="M25.9368421,24.1290323 L23.8526316,24.1290323 L23.8526316,7.02504408 C25.4358039,6.69515497 26.6315789,5.26228574 26.6315789,3.5483871 C26.6315789,1.59192284 25.0731703,0 23.1578947,0 C21.2426192,0 19.6842105,1.59192284 19.6842105,3.5483871 C19.6842105,5.26159274 20.8799856,6.69515497 22.4631579,7.02504408 L22.4631579,24.1290323 L20.3789474,24.1290323 C19.9956209,24.1290323 19.6842105,24.4461001 19.6842105,24.8387097 L19.6842105,40.4516129 C19.6842105,42.4080772 21.2426192,44 23.1578947,44 C25.0731703,44 26.6315789,42.4080772 26.6315789,40.4516129 L26.6315789,24.8387097 C26.6315789,24.4461001 26.3201685,24.1290323 25.9368421,24.1290323 Z M21.0736842,3.5483871 C21.0736842,2.37471646 22.008933,1.41935484 23.1578947,1.41935484 C24.3068565,1.41935484 25.2421053,2.37471646 25.2421053,3.5483871 C25.2421053,4.72205773 24.3068565,5.67741935 23.1578947,5.67741935 C22.008933,5.67741935 21.0736842,4.72205773 21.0736842,3.5483871 Z M25.2421053,25.5483871 L25.2421053,26.9677419 L21.0736842,26.9677419 L21.0736842,25.5483871 L25.2421053,25.5483871 Z M23.1578947,42.5806452 C22.008933,42.5806452 21.0736842,41.6252835 21.0736842,40.4516129 L21.0736842,28.3870968 L25.2421053,28.3870968 L25.2421053,40.4516129 C25.2421053,41.6252835 24.3068565,42.5806452 23.1578947,42.5806452 Z" id="Shape"></path>
        </g>
    </g>
</svg>`,
          radiografie: `
<svg width="56px" height="56px" viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M30.9223635,22.6474605 C32.6928709,23.4472656 33.8276365,25.204102 33.8378906,27.1420896 C33.8378906,27.1420896 33.8378906,27.2207031 33.8378906,27.2548833 C33.8378906,27.282227 33.8378906,27.4086916 33.8378906,27.4941406 C33.7934572,28.0991209 34.2480469,28.6254885 34.8530271,28.6733396 C34.8598635,28.6733396 34.8598635,28.6733396 34.8598635,28.6733396 L48.7778322,29.6713865 L48.8564457,29.6713865 C49.4306645,29.6713865 49.9160156,29.227051 49.9536135,28.6494145 L49.9536135,28.4716793 C49.9536135,28.0307615 50.0219729,27.5966793 50.0219729,27.1420896 C50.0117188,18.8398438 45.1650395,11.3032229 37.6113281,7.86132813 C37.054199,7.61181663 36.4047854,7.85791038 36.1586916,8.40820313 L30.3754885,21.1811521 C30.1191406,21.7382813 30.3652344,22.3876958 30.9155271,22.6440428 C30.9189457,22.6440428 30.9189457,22.6440428 30.9223635,22.6474605 Z M37.686523,10.342773 C43.9277344,13.624023 47.8515625,20.0874021 47.8652344,27.1420896 C47.8652344,27.2309573 47.8652344,27.3129885 47.8652344,27.3950198 L36.0151365,26.5473635 C35.8237302,24.3564458 34.6376957,22.374023 32.7954104,21.1743166 L37.686523,10.342773 Z" id="Shape"></path>
            <path d="M32.4194334,33.2773437 C32.0502928,32.7954104 31.3632813,32.6997072 30.8813479,33.0688479 C29.117676,34.4155271 26.6738281,34.4155271 24.913574,33.0688479 C24.4282229,32.6997072 23.7377928,32.7954104 23.3686521,33.2773437 L14.8647459,44.4370115 C14.4956053,44.9189458 14.5913084,45.609375 15.0732418,45.9750979 C22.6337895,51.7651365 33.1372073,51.7651365 40.6977541,45.9750979 C41.1831053,45.609375 41.2753906,44.9189458 40.9096678,44.4370115 L32.4194334,33.2773437 Z M27.890625,48.1215823 C24.110352,48.1113281 20.4189458,46.9628906 17.3017583,44.8232418 L24.5034178,35.3657229 C26.6157229,36.5175781 29.1723635,36.5175781 31.2880855,35.3657229 L38.4863281,44.8232418 C35.3657229,46.9628906 31.6743166,48.1113281 27.890625,48.1215823 Z" id="Shape"></path>
            <path d="M21.7109375,28.2871094 C21.9057615,28.0649416 21.9980469,27.7744145 21.9775395,27.4838865 C21.9775395,27.4086916 21.9775395,27.3300781 21.9775395,27.2412105 C21.9775395,27.1557615 21.9775395,27.1625979 21.9775395,27.1284178 C21.9809572,25.1938479 23.1225584,23.4335938 24.889648,22.6337895 C25.4433594,22.3876958 25.6860354,21.7382813 25.436523,21.1811521 L19.6362302,8.40820313 C19.383301,7.85791038 18.7338865,7.61181663 18.1835937,7.86132813 C10.609375,11.2861333 5.73193337,18.8295896 5.71484375,27.1420896 C5.71484375,27.5966793 5.71484375,28.0307615 5.77978537,28.4716793 L5.77978537,28.6494145 C5.82421875,29.227051 6.30273437,29.6713865 6.8837895,29.6713865 L7.00341775,29.6713865 L21,28.6733396 C21.2768552,28.6391604 21.5332031,28.5024416 21.7109375,28.2871094 Z M7.91601562,27.3950198 C7.91601562,27.3095708 7.91601562,27.2241209 7.91601562,27.1420896 C7.9296875,20.0874021 11.8500979,13.624023 18.094727,10.342773 L22.9858396,21.1743166 C21.1435542,22.374023 19.9575197,24.3564458 19.7626957,26.5473635 L7.91601562,27.3950198 Z" id="Shape"></path>
            <path d="M23.3071291,27.8769531 C23.3071291,30.4096677 25.3579104,32.460449 27.890625,32.460449 C30.4233396,32.460449 32.4741209,30.4096677 32.4741209,27.8769531 C32.4741209,25.3476562 30.4233396,23.2934572 27.890625,23.2934572 C25.3613281,23.3002928 23.3105469,25.3476562 23.3071291,27.8769531 Z M27.890625,25.4946291 C29.2065427,25.4946291 30.272949,26.5610354 30.272949,27.8769531 C30.272949,29.1962895 29.2065427,30.2661135 27.890625,30.2661135 C26.5747072,30.2661135 25.5048832,29.1962895 25.5048832,27.8769531 C25.5048832,26.5610354 26.5747072,25.4946291 27.890625,25.4946291 Z" id="Shape"></path>
            <path d="M27.890625,55.78125 C43.2919918,55.78125 55.78125,43.2919918 55.78125,27.890625 C55.78125,12.4892583 43.2919918,0 27.890625,0 C12.492676,0 0.0068359375,12.4790041 0,27.8769531 C0.013671875,43.2783207 12.4892583,55.7641604 27.890625,55.78125 Z M27.890625,2.1875 C42.0786135,2.1875 53.5800781,13.6889646 53.5800781,27.8769531 C53.5800781,42.0717771 42.0786135,53.5732417 27.890625,53.5732417 C13.7026365,53.5732417 2.20117188,42.0717771 2.20117188,27.8769531 C2.2182615,13.695801 13.7094729,2.20458963 27.890625,2.1875 Z" id="Shape"></path>
        </g>
    </g>
</svg>`,
          document: `
<svg width="32px" height="40px" viewBox="0 0 32 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(0.383333, 0.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M24,14.6666667 L7.33333333,14.6666667 C6.96533333,14.6666667 6.66666667,14.9646667 6.66666667,15.3333333 C6.66666667,15.702 6.96533333,16 7.33333333,16 L24,16 C24.368,16 24.6666667,15.702 24.6666667,15.3333333 C24.6666667,14.9646667 24.368,14.6666667 24,14.6666667 Z" id="Path"></path>
            <path d="M7.33333333,10.6666667 L14,10.6666667 C14.368,10.6666667 14.6666667,10.3686667 14.6666667,10 C14.6666667,9.63133333 14.368,9.33333333 14,9.33333333 L7.33333333,9.33333333 C6.96533333,9.33333333 6.66666667,9.63133333 6.66666667,10 C6.66666667,10.3686667 6.96533333,10.6666667 7.33333333,10.6666667 Z" id="Path"></path>
            <path d="M24,20 L7.33333333,20 C6.96533333,20 6.66666667,20.298 6.66666667,20.6666667 C6.66666667,21.0353333 6.96533333,21.3333333 7.33333333,21.3333333 L24,21.3333333 C24.368,21.3333333 24.6666667,21.0353333 24.6666667,20.6666667 C24.6666667,20.298 24.368,20 24,20 Z" id="Path"></path>
            <path d="M24,25.3333333 L7.33333333,25.3333333 C6.96533333,25.3333333 6.66666667,25.6313333 6.66666667,26 C6.66666667,26.3686667 6.96533333,26.6666667 7.33333333,26.6666667 L24,26.6666667 C24.368,26.6666667 24.6666667,26.3686667 24.6666667,26 C24.6666667,25.6313333 24.368,25.3333333 24,25.3333333 Z" id="Path"></path>
            <path d="M24,30.6666667 L7.33333333,30.6666667 C6.96533333,30.6666667 6.66666667,30.9646667 6.66666667,31.3333333 C6.66666667,31.702 6.96533333,32 7.33333333,32 L24,32 C24.368,32 24.6666667,31.702 24.6666667,31.3333333 C24.6666667,30.9646667 24.368,30.6666667 24,30.6666667 Z" id="Path"></path>
            <path d="M21.6093333,0 L1.0658141e-14,0 L1.0658141e-14,40 L31.3333333,40 L31.3333333,9.724 L21.6093333,0 Z M22,2.276 L29.0573333,9.33333333 L22,9.33333333 L22,2.276 Z M1.33333333,38.6666667 L1.33333333,1.33333333 L20.6666667,1.33333333 L20.6666667,10.6666667 L30,10.6666667 L30,38.6666667 L1.33333333,38.6666667 Z" id="Shape"></path>
        </g>
    </g>
</svg>`,
          nota: `<svg width="36px" height="35px" viewBox="0 0 36 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(0.000000, 0.027378)" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M32.4266113,21.9372924 C31.9433105,21.9372924 31.5516113,22.3289917 31.5516113,22.8122924 L31.5516113,30.5813354 C31.5499023,32.0305542 30.3758301,33.2049683 28.9266113,33.2063354 L4.375,33.2063354 C2.92578125,33.2049683 1.75170896,32.0305542 1.75,30.5813354 L1.75,7.77972409 C1.75170896,6.33084712 2.92578125,5.15643306 4.375,5.15472409 L12.1440429,5.15472409 C12.6273437,5.15472409 13.0190429,4.76302483 13.0190429,4.27972409 C13.0190429,3.79676513 12.6273437,3.40472409 12.1440429,3.40472409 L4.375,3.40472409 C1.95986332,3.40745847 0.002734375,5.36458733 0,7.77972409 L0,30.5816772 C0.002734375,32.996814 1.95986332,34.9539428 4.375,34.9566772 L28.9266113,34.9566772 C31.341748,34.9539428 33.298877,32.996814 33.3016113,30.5816772 L33.3016113,22.8122924 C33.3016113,22.3289917 32.9099121,21.9372924 32.4266113,21.9372924 Z" id="Path"></path>
            <path d="M32.9550293,1.15330806 C31.4172852,-0.384436019 28.9242188,-0.384436019 27.3864746,1.15330806 L11.7759277,16.7638549 C11.6689453,16.8708373 11.5916992,17.0034546 11.5513672,17.14906 L9.4985352,24.5602417 C9.41411135,24.8640991 9.4999023,25.1894897 9.72275395,25.4126831 C9.94594729,25.6355346 10.2713379,25.7213257 10.5751953,25.6372436 L17.986377,23.5840698 C18.1319824,23.5437377 18.2645996,23.4664917 18.3715821,23.3595092 L33.9817871,7.74862056 C35.5171386,6.20985107 35.5171386,3.71883544 33.9817871,2.18006587 L32.9550293,1.15330806 Z M13.6824707,17.3326049 L26.458496,4.55623774 L30.5788574,8.67659909 L17.8024902,21.4529663 L13.6824707,17.3326049 Z M12.8594238,18.9841674 L16.1512696,22.2763549 L11.5978516,23.5379272 L12.8594238,18.9841674 Z M32.7444824,6.51131587 L31.8165039,7.43929441 L27.6958008,3.31859128 L28.624121,2.39061274 C29.4782715,1.53646233 30.8632324,1.53646233 31.7173828,2.39061274 L32.7444824,3.41737056 C33.5972656,4.27254638 33.5972656,5.65648191 32.7444824,6.51131587 L32.7444824,6.51131587 Z" id="Shape"></path>
        </g>
    </g>
</svg>`,
          importaFisa: `
<svg width="34px" height="42px" viewBox="0 0 34 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(0.500000, 0.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M22.633255,0.324600199 C22.4406738,0.121765137 22.1766357,0 21.9029846,0 L5.51884462,0 C2.49554443,0 0,2.48561094 0,5.50859072 L0,36.4910889 C0,39.5143891 2.49554443,42 5.51884462,42 L27.7563629,42 C30.7796631,42 33.2752075,39.5143891 33.2752075,36.4910889 L33.2752075,11.8897247 C33.2752075,11.626007 33.1534424,11.3725434 32.9810486,11.1796417 L22.633255,0.324600199 Z M22.927414,3.58117676 L29.8564911,10.8550415 L25.3521424,10.8550415 C24.0130463,10.8550415 22.927414,9.77966309 22.927414,8.440567 L22.927414,3.58117676 Z M27.7563629,39.9710083 L5.51884462,39.9710083 C3.62187197,39.9710083 2.0289917,38.3986358 2.0289917,36.4910889 L2.0289917,5.50859072 C2.0289917,3.61161806 3.61161806,2.0289917 5.51884462,2.0289917 L20.8984223,2.0289917 L20.8984223,8.440567 C20.8984223,10.9056701 22.8870392,12.8840332 25.3521424,12.8840332 L31.2462158,12.8840332 L31.2462158,36.4910889 C31.2462158,38.3986358 29.6635895,39.9710083 27.7563629,39.9710083 Z" id="Shape"></path>
            <path d="M24.9868469,32.9711151 L8.28836055,32.9711151 C7.73048399,32.9711151 7.27386475,33.427414 7.27386475,33.9856109 C7.27386475,34.5434875 7.73048399,35.0001068 8.28836055,35.0001068 L24.9971008,35.0001068 C25.5549774,35.0001068 26.0115967,34.5434875 26.0115967,33.9856109 C26.0115967,33.427414 25.5549774,32.9711151 24.9868469,32.9711151 Z" id="Path"></path>
            <path d="M11.4433594,22.055191 L15.6231079,17.5607758 L15.6231079,28.6391602 C15.6231079,29.1970367 16.0797272,29.653656 16.6376037,29.653656 C17.1958008,29.653656 17.6520996,29.1970367 17.6520996,28.6391602 L17.6520996,17.5607758 L21.8318481,22.055191 C22.0346832,22.2679596 22.2987213,22.3797912 22.5723725,22.3797912 C22.8159027,22.3797912 23.0696869,22.2884674 23.2622681,22.1058197 C23.6682587,21.7203369 23.6987,21.0810699 23.3132172,20.6753998 L17.3681945,14.2942657 C17.175293,14.0914307 16.9115753,13.9696655 16.6276703,13.9696655 C16.3434448,13.9696655 16.0797272,14.0914307 15.887146,14.2942657 L9.94212343,20.6753998 C9.55664062,21.0810699 9.58708193,21.7302704 9.99275205,22.1058197 C10.4189301,22.4913025 11.0578766,22.4608612 11.4433594,22.055191 Z" id="Path"></path>
        </g>
    </g>
</svg>`
        },
        moment,
        isOpen: false,
        options: {
          index: 0,
          shareEl: false
        },
        items: [],
        picOrder: ['inainte', 'dupa'],
        imgFormats: ['.jpg', '.JPG', '.jpeg', '.png', '.bmp', '.gif'],
        refresh: undefined
      }
    },
    filters: {
      shorten (val) {
        return val && val.length > 50 ? `${val.substr(0, 50)}... ` : val
      }
    },
    computed: {
      ...mapState({
        pacient: 'pacient'
      }),
      tratamente () {
        return this.$store.state.pacient.history &&
          this.$store.state.pacient.history
            .filter(obj => obj.type !== 'scrisoare')
            .map(obj => obj)
      },
      componentName () {
        const name = this.$store.state.modalComponentName
        // eslint-disable-next-line
        return name ? () => import(`@/components/${name}`) : ''
      }
    },
    methods: {
      refreshData () {
        const id = this.$router.currentRoute.params.id;
        this.$store.dispatch('refresh_patient', { id });
      },
      pics (item) {
        return item && JSON.parse(JSON.stringify(item))
      },
      edit (item) {
        const obj = {
          name: this.meniu[item.type].modal,
          val: true,
          setValues: JSON.parse(JSON.stringify(item))
        }
        item.denumire === 'punte' && (obj.name = 'addPunteModal')
        this.$store.commit('SET_MODAL', obj)
        this.$store.commit('SET_KEY_VALUE', {
          key: 'isUpdateModal',
          value: true
        })
      },

      makePicUrl (pic) {
        return pic ? `/history-imgs/${this.pacient._id}/thumb-${pic.name}.png` : ''
      },

      openModal (item, type) {
        const obj = {
          val: true,
          name: item.modal,
          setValues: {
            id: new Date().getTime(),
            type
          }
        }
        this.$store.commit('SET_KEY_VALUE', {
          key: 'isUpdateModal',
          value: false
        })
        this.$store.commit('SET_MODAL', obj)
      },

      showPhotoSwipe (item, index) {
        if (item.type === 'tratament') {
          this.reorder(item)
        } else {
          item.pics.forEach(obj => {
            this.items.push({
              src: `/history-imgs/${this.pacient._id}/${obj.name}`,
              w: obj.w,
              h: obj.h
            })
          })
        }
        this.isOpen = true
        this.$set(this.options, 'index', index)
      },

      hidePhotoSwipe () {
        this.items = []
        this.isOpen = false
      },

      reorder (item) {
        const reorder = []
        this.picOrder.forEach(val => {
          item.pics.forEach(obj => {
            obj.name.includes(val) && reorder.push(obj)
          })
        })
        reorder.forEach(obj => {
          this.items.push({
            src: `/history-imgs/${this.pacient._id}/${obj.name}`,
            w: obj.w,
            h: obj.h
          })
        })
      },

      isImg (item) {
        return item.pics && item.pics.length > 0 ? this.imgFormats.some(extension => item.pics[0].name.includes(extension)) : false
      }
    },
    components: {
      'v-photoswipe': PhotoSwipe,
      'v-photoswipe-gallery': PhotoSwipeGallery
    },
    destroyed () {
      clearInterval(this.refresh)
    }
  }
</script>

<style lang="less">

  @import "../assets/styles/mixin";
  @import "../assets/styles/var";

  .patient-history {
    .pin-img {
      z-index: 2;
      position: absolute;
      left: 40px;
      top: -7px;
    }
    h4.nota-title {
      padding-top: 6px;
      font-size: 1.6rem;
    }
  }
</style>
